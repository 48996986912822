import React from "react";
import logo from "./Assets/logo.png";
import "./style.css";

const Right = () => {
  return (
    <div className="right">
      <img src={logo} alt="" className="rounded-full" />
      <h1>Gbludo REAL CASH!</h1>
    </div>
  );
};

export default Right;
